export enum CommonActionType {
  RESET_COMMON = "@COMMON/RESET_COMMON",

  SET_COMMON_ACTION = "@COMMON/SET_COMMON_ACTION",
  SET_COMMON_MEMBER = "@COMMON/SET_COMMON_MEMBER",
  SET_COMMON_GOVERNANCE = "@COMMON/SET_COMMON_GOVERNANCE",

  SET_DISCUSSION_CREATION_DATA = "@COMMON/SET_DISCUSSION_CREATION_DATA",
  SET_PROPOSAL_CREATION_DATA = "@COMMON/SET_PROPOSAL_CREATION_DATA",

  CREATE_DISCUSSION = "@COMMON/CREATE_DISCUSSION",
  CREATE_DISCUSSION_SUCCESS = "@COMMON/CREATE_DISCUSSION_SUCCESS",
  CREATE_DISCUSSION_FAILURE = "@COMMON/CREATE_DISCUSSION_FAILURE",

  EDIT_DISCUSSION = "@COMMON/EDIT_DISCUSSION",
  EDIT_DISCUSSION_SUCCESS = "@COMMON/EDIT_DISCUSSION_SUCCESS",
  EDIT_DISCUSSION_FAILURE = "@COMMON/EDIT_DISCUSSION_FAILURE",

  GET_FEED_ITEMS = "@COMMON/GET_FEED_ITEMS",
  GET_FEED_ITEMS_SUCCESS = "@COMMON/GET_FEED_ITEMS_SUCCESS",
  GET_FEED_ITEMS_FAILURE = "@COMMON/GET_FEED_ITEMS_FAILURE",
  GET_FEED_ITEMS_CANCEL = "@COMMON/GET_FEED_ITEMS_CANCEL",

  GET_PINNED_FEED_ITEMS = "@COMMON/GET_PINNED_FEED_ITEMS",
  GET_PINNED_FEED_ITEMS_SUCCESS = "@COMMON/GET_PINNED_FEED_ITEMS_SUCCESS",
  GET_PINNED_FEED_ITEMS_FAILURE = "@COMMON/GET_PINNED_FEED_ITEMS_FAILURE",
  GET_PINNED_FEED_ITEMS_CANCEL = "@COMMON/GET_PINNED_FEED_ITEMS_CANCEL",

  SEARCH_FEED_ITEMS = "@COMMON/SEARCH_FEED_ITEMS",
  SET_SEARCH_STATE = "@COMMON/SET_SEARCH_STATE",
  RESET_SEARCH_STATE = "@COMMON/RESET_SEARCH_STATE",
  UPDATE_SEARCH_FEED_ITEMS = "@COMMON/UPDATE_SEARCH_FEED_ITEMS",
  SET_IS_SEARCHING_FEED_ITEMS = "@COMMON/SET_IS_SEARCHING_FEED_ITEMS",

  SET_FEED_STATE = "@COMMON/SET_FEED_STATE",

  ADD_NEW_FEED_ITEMS = "@COMMON/ADD_NEW_FEED_ITEMS",

  ADD_NEW_PINNED_FEED_ITEMS = "@COMMON/ADD_NEW_PINNED_FEED_ITEMS",
  UNPIN_FEED_ITEMS = "@COMMON/UNPIN_FEED_ITEMS",

  UPDATE_FEED_ITEM = "@COMMON/UPDATE_FEED_ITEM",

  RESET_FEED_ITEMS = "@COMMON/RESET_FEED_ITEMS",

  SET_IS_NEW_PROJECT_CREATED = "@COMMON/SET_IS_NEW_PROJECT_CREATED",

  CREATE_PROPOSAL = "@COMMON/CREATE_PROPOSAL",
  CREATE_PROPOSAL_SUCCESS = "@COMMON/CREATE_PROPOSAL_SUCCESS",
  CREATE_PROPOSAL_FAILURE = "@COMMON/CREATE_PROPOSAL_FAILURE",

  CREATE_FUNDING_PROPOSAL = "@COMMON/CREATE_FUNDING_PROPOSAL",
  CREATE_FUNDING_PROPOSAL_SUCCESS = "@COMMON/CREATE_FUNDING_PROPOSAL_SUCCESS",
  CREATE_FUNDING_PROPOSAL_FAILURE = "@COMMON/CREATE_FUNDING_PROPOSAL_FAILURE",

  SET_SHARED_FEED_ITEM_ID = "@COMMON/SET_SHARED_FEED_ITEM_ID",
  SET_SHARED_FEED_ITEM = "@COMMON/SET_SHARED_FEED_ITEM",

  SET_RECENT_STREAM_ID = "@COMMON/SET_RECENT_STREAM_ID",

  SET_RECENT_ASSIGNED_CIRCLE_BY_MEMBER = "@COMMON/SET_RECENT_ASSIGNED_CIRCLE_BY_MEMBER",
  RESET_RECENT_ASSIGNED_CIRCLE_BY_MEMBER = "@COMMON/RESET_RECENT_ASSIGNED_CIRCLE_BY_MEMBER",

  SET_FEED_ITEM_UPDATED_AT = "@COMMON/SET_FEED_ITEM_UPDATED_AT",
}
