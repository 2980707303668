export enum InboxActionType {
  RESET_INBOX = "@INBOX/RESET_INBOX",

  GET_INBOX_ITEMS = "@INBOX/GET_INBOX_ITEMS",
  GET_INBOX_ITEMS_SUCCESS = "@INBOX/GET_INBOX_ITEMS_SUCCESS",
  GET_INBOX_ITEMS_FAILURE = "@INBOX/GET_INBOX_ITEMS_FAILURE",
  GET_INBOX_ITEMS_CANCEL = "@INBOX/GET_INBOX_ITEMS_CANCEL",

  REFETCH_INBOX_ITEMS = "@INBOX/REFETCH_INBOX_ITEMS",

  SEARCH_INBOX_ITEMS = "@INBOX/SEARCH_INBOX_ITEMS",
  SET_SEARCH_STATE = "@INBOX/SET_SEARCH_STATE",
  RESET_SEARCH_STATE = "@INBOX/RESET_SEARCH_STATE",
  RESET_SEARCH_INBOX_ITEMS = "@INBOX/RESET_SEARCH_INBOX_ITEMS",
  UPDATE_SEARCH_INBOX_ITEMS = "@INBOX/UPDATE_SEARCH_INBOX_ITEMS",
  SET_IS_SEARCHING_INBOX_ITEMS = "@INBOX/SET_IS_SEARCHING_INBOX_ITEMS",

  ADD_NEW_INBOX_ITEMS = "@INBOX/ADD_NEW_INBOX_ITEMS",

  UPDATE_INBOX_ITEM = "@INBOX/UPDATE_INBOX_ITEM",
  UPDATE_FEED_ITEM = "@INBOX/UPDATE_FEED_ITEM",
  UPDATE_CHAT_CHANNEL_ITEM = "@INBOX/UPDATE_CHAT_CHANNEL_ITEM",
  UPDATE_CHAT_CHANNEL_ITEM_EMPTINESS = "@INBOX/UPDATE_CHAT_CHANNEL_ITEM_EMPTINESS",

  RESET_INBOX_ITEMS = "@INBOX/RESET_INBOX_ITEMS",

  SET_HAS_MORE_INBOX_ITEMS = "@INBOX/SET_HAS_MORE_INBOX_ITEMS",

  SET_SHARED_FEED_ITEM_ID = "@INBOX/SET_SHARED_FEED_ITEM_ID",
  SET_SHARED_INBOX_ITEM = "@INBOX/SET_SHARED_INBOX_ITEM",

  ADD_CHAT_CHANNEL_ITEM = "@INBOX/ADD_CHAT_CHANNEL_ITEM",
  REMOVE_EMPTY_CHAT_CHANNEL_ITEMS = "@INBOX/REMOVE_EMPTY_CHAT_CHANNEL_ITEMS",

  SAVE_LAST_STATE = "@INBOX/SAVE_LAST_STATE",

  SET_INBOX_ITEM_UPDATED_AT = "@INBOX/SET_INBOX_ITEM_UPDATED_AT",
}
