export enum OptimisticActionType {
  SET_OPTIMISTIC_FEED_ITEM = "@OPTIMISTIC/SET_OPTIMISTIC_FEED_ITEM",
  UPDATE_OPTIMISTIC_FEED_ITEM = "@OPTIMISTIC/UPDATE_OPTIMISTIC_FEED_ITEM",
  REMOVE_OPTIMISTIC_FEED_ITEM = "@OPTIMISTIC/REMOVE_OPTIMISTIC_FEED_ITEM",
  REMOVE_OPTIMISTIC_INBOX_FEED_ITEM = "@OPTIMISTIC/REMOVE_OPTIMISTIC_INBOX_FEED_ITEM",

  SET_OPTIMISTIC_DISCUSSION_MESSAGES = "@OPTIMISTIC/SET_OPTIMISTIC_DISCUSSION_MESSAGES",
  CLEAR_OPTIMISTIC_DISCUSSION_MESSAGES = "@OPTIMISTIC/CLEAR_OPTIMISTIC_DISCUSSION_MESSAGES",

  SET_INSTANT_DISCUSSION_MESSAGES_ORDER = "@OPTIMISTIC/SET_INSTANT_DISCUSSION_MESSAGES_ORDER",
  CLEAR_INSTANT_DISCUSSION_MESSAGES_ORDER = "@OPTIMISTIC/CLEAR_INSTANT_DISCUSSION_MESSAGES_ORDER",

  CLEAR_CREATED_OPTIMISTIC_FEED_ITEM = "@OPTIMISTIC/CLEAR_CREATED_OPTIMISTIC_FEED_ITEM",
  RESET_OPTIMISTIC_STATE = "RESET_OPTIMISTIC_STATE",
}
